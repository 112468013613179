<template>
  <!--begin::Basic info-->
  <div class="card mb-5 mb-xl-10">
    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body top p-0">
        <!--begin::Input group-->
        <!-- <div class="row mb-6"> -->
        <div style="height: 64vh; width: 100%">
          <l-map
            v-model="zoom"
            v-model:zoom="zoom"
            :zoomAnimation="true"
            :options="{ zoomControl: false }"
            :center="[-6.304159, 106.724997]"
          >
            <l-tile-layer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              layer-type="base"
              name="OpenStreetMap"
            />
            <l-control-layers />
            <l-control-zoom position="bottomright"></l-control-zoom>

            <template v-for="(item, index) in markers" :key="index">
              <!-- icon -->
              <l-marker :lat-lng="[item.issu.latitude, item.issu.longitude]">
                <l-icon :icon-url="icon" :icon-size="iconSize" />
                <l-popup>
                  <div class="card">
                    <div class="card-body text-start">
                      <div class="fs-5 fw-bolder">
                        {{ item.territory.name }}
                      </div>
                      <div class="fw-bold">
                        oleh : {{ item.created_by.name }}
                      </div>
                      <div class="text-muted">
                        {{ item.issu.date }}
                      </div>
                      <div class="">
                        {{ item.issu.description }}
                      </div>
                      <img
                        class="img-fluid"
                        style="max-height: 100px"
                        :src="item.issu.foto"
                        alt="foto kegiatan"
                      />
                    </div>
                  </div>
                </l-popup>
              </l-marker>
            </template>
          </l-map>
        </div>
        <!-- </div> -->
        <!--end::Input group-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "issuemap-Index",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Hot Issue");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllIssueDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";

    const icon = path + "other/png/pelanggaran-1.png";

    // const markers = ref([
    //   {
    //     id: 1,
    //     position: [-6.30219, 106.725977],
    //     data: {
    //       keterangan: "Kawasan Kumuh di Ciputat",
    //       tanggal: "30 Agustus 2021",
    //       img: path + "other/png/Rectangle -3.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    //   {
    //     id: 2,
    //     position: [-6.303169, 106.724987],
    //     data: {
    //       keterangan: "Banjir di daerah A",
    //       tanggal: "8 Januari 2021",
    //       img: path + "other/png/Rectangle -2.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    // ]);

    const markers = computed(() => store.state.IssueDashboardModule.all);

    watch(markers, () => {
      // console.log(markers.value);
    });

    return {
      center,
      markers,
      zoom,
      icon,
      iconSize,
    };
  },
});
</script>
