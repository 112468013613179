
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
  LPopup,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "issuemap-Index",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
    LPopup,
  },
  setup() {
    setCurrentPageTitle("Hot Issue");

    const store = useStore();
    // const router = useRouter();

    store.dispatch("AllIssueDashboard");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";

    const icon = path + "other/png/pelanggaran-1.png";

    // const markers = ref([
    //   {
    //     id: 1,
    //     position: [-6.30219, 106.725977],
    //     data: {
    //       keterangan: "Kawasan Kumuh di Ciputat",
    //       tanggal: "30 Agustus 2021",
    //       img: path + "other/png/Rectangle -3.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    //   {
    //     id: 2,
    //     position: [-6.303169, 106.724987],
    //     data: {
    //       keterangan: "Banjir di daerah A",
    //       tanggal: "8 Januari 2021",
    //       img: path + "other/png/Rectangle -2.png",
    //       deskripsi: "Keterangan tambahannya ...",
    //       oleh: "Bayu",
    //     },
    //   },
    // ]);

    const markers = computed(() => store.state.IssueDashboardModule.all);

    watch(markers, () => {
      // console.log(markers.value);
    });

    return {
      center,
      markers,
      zoom,
      icon,
      iconSize,
    };
  },
});
